// import logo from './logo.svg';
import React from 'react';
import ReactPlayer from 'react-player';
import './App.css';
import Battery from './battery';
import styled from 'styled-components';

const AppContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: #002fff;
`;

const VideoBackground = styled(ReactPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;

   
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: white;
`;

function App() {
  return (
    <AppContainer>
    {/* <VideoBackground
        url="./campaign.mp4"
        type="video/mp4"
        playing
        autoPlay
        loop
        muted
        controls={false}
        width="100%"
        height="120%"
        
      /> */}
      <Content>
    
      <header className="App-header">
        <h1 style={{ fontFamily: 'StreetToxicDemo', fontSize: "50px", maxWidth: "80%", marginTop: "5%", textAlign: "center" }}>VNDER CONSTRUCTION</h1>
        {/* <Battery /> */}
      </header>
    </Content>
    </AppContainer>
  );
}

export default App;