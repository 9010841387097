// src/Battery.js
import React from 'react';
import styled, { keyframes } from 'styled-components';

const fill = keyframes`
  0% { width: 0; background: #fff}
  50% { width: 50%; background: #fff}
  100% { width: 100%; background: #fff}
`;

const moveBackground = keyframes`
  0% { background-position: 0px 0; }
  100% { background-position: 200px 0; }
`;

const BatteryContainer = styled.div`
  width: 200px;
  height: 100px;
  border: 5px solid white;
  position: relative;
  background: repeating-linear-gradient(
        45deg,
        #002eff,
        #002eff 10px,
        #fff 10px,
        #fff 20px
    );  
    animation: ${moveBackground} 10s infinite;
     &:before {
    content: '';
    position: absolute;
    right: -15px;
    top: 30px;
    width: 10px;
    height: 40px;
    background: white;
  }
`;

const BatteryLevel = styled.div`
  height: auto;
`;

const Battery = () => {
  return (
    <BatteryContainer>
      <BatteryLevel />
    </BatteryContainer>
  );
};

export default Battery;